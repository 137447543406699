import { Action, Reducer } from './appStateContext'

enum TitleActionType {
    SetTitle = 'SetTitle',
    ClearTitle = 'ClearTitle',
}

interface SetTitleAction extends Action {
    type: TitleActionType.SetTitle
    title: string
}

interface ClearTitleAction extends Action {
    type: TitleActionType.ClearTitle
}

type TitleAction = SetTitleAction | ClearTitleAction

export const setTitle = (title: string): SetTitleAction => ({
    type: TitleActionType.SetTitle,
    title,
})
export const clearTitle = (): ClearTitleAction => ({ type: TitleActionType.ClearTitle })

type TitleState = string | null

export const titleReducer: Reducer<TitleState, TitleAction> = (state: TitleState, action: TitleAction): TitleState => {
    switch (action.type) {
        case TitleActionType.SetTitle: {
            return action.title
        }
        case TitleActionType.ClearTitle: {
            return null
        }
        default: {
            return state
        }
    }
}
