import { Action, Reducer } from './appStateContext'

enum MessageActionType {
    ShowMessage = 'ShowMessage',
    HideMessage = 'HideMessage',
}

interface ShowMessageAction extends Action {
    type: MessageActionType.ShowMessage
    message: string
}

interface HideMessageAction extends Action {
    type: MessageActionType.HideMessage
}

type MessageAction = ShowMessageAction | HideMessageAction

export const showMessage = (message: string): ShowMessageAction => ({
    type: MessageActionType.ShowMessage,
    message,
})
export const hideMessage = (): HideMessageAction => ({ type: MessageActionType.HideMessage })

type MessageState = string | null

export const messageReducer: Reducer<MessageState, MessageAction> = (
    state: MessageState,
    action: MessageAction
): MessageState => {
    switch (action.type) {
        case MessageActionType.ShowMessage: {
            return action.message
        }
        case MessageActionType.HideMessage: {
            return null
        }
        default: {
            return state
        }
    }
}
