import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import logo from '../../assets/logo.png'

const strings = {
    text: {
        appName: 'udostepnij.pl',
    },
}

const useStyles = makeStyles({
    container: {
        paddingBottom: 16,
        paddingTop: 128,
    },
    logo: {
        height: 200,
        width: 200,
    },
    title: {
        fontSize: 72,
    },
})

const LoginLogo = (): React.ReactElement => {
    const classes = useStyles()

    return (
        <Grid
            container={true}
            spacing={0}
            className={classes.container}
            alignItems="center"
            justifyContent="center"
            direction="column"
        >
            <Grid item={true}>
                <img
                    className={classes.logo}
                    src={logo}
                    alt={strings.text.appName}
                />
            </Grid>
            <Grid item={true}>
                <Typography className={classes.title}>{strings.text.appName}</Typography>
            </Grid>
        </Grid>
    )
}

export default React.memo(LoginLogo)
