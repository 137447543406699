import React, { useEffect } from 'react'
import withAppCanvas from '../../components/AppCanvas'
import { useAppStateContext } from '../../state/appStateContext'
import { clearMenuItem } from '../../state/menuItemActions'
import { clearTitle } from '../../state/titleActions'

const HomePage = (): React.ReactElement | null => {
    const { state, dispatch } = useAppStateContext()

    useEffect(() => {
        const { menuItem, title } = state

        if (menuItem !== null) {
            dispatch(clearMenuItem())
        }

        if (title !== null) {
            dispatch(clearTitle())
        }
    }, [])

    return null
}

export default withAppCanvas(React.memo(HomePage))
