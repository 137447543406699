import { AccessToken } from '../api/response'

enum LocalStorageKey {
    Auth = 'Auth',
}

class LocalStore {
    public clearStore = (): void => {
        try {
            localStorage.clear()
        } catch (error) {
            // Ignore
        }
    }

    public storeAccessToken = (auth: AccessToken): void => {
        try {
            localStorage.setItem(LocalStorageKey.Auth, JSON.stringify(auth))
        } catch (error) {
            // Ignore
        }
    }

    public removeAccessToken = (): void => {
        try {
            localStorage.removeItem(LocalStorageKey.Auth)
        } catch (error) {
            // Ignore
        }
    }

    public accessToken = (): AccessToken | null => {
        try {
            const json: string | null = localStorage.getItem(LocalStorageKey.Auth)
            if (json) {
                return JSON.parse(json)
            }

            return null
        } catch (error) {
            return null
        }
    }
}

const localStore = new LocalStore()

export default localStore
