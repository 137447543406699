import React from 'react'
import { TableCell, TableRow, Tooltip, IconButton } from '@mui/material'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import _ from 'lodash'
import { UserDetails } from '../../api/response/UserDetails'

const strings = {
    addingArticles: {
        allowed: 'Dozwolone',
        dissalowed: 'Zablokowane',
    },
    button: {
        disallow: 'Zablokuj',
        allow: 'Zezwól',
    },
}

interface ComponentProps {
    item: UserDetails
    onAllowAddingArticlesChanged: (item: UserDetails, allowAddingArticles: boolean) => void
}

class UserTableRow extends React.Component<ComponentProps> {
    //#region Lifecycle
    public shouldComponentUpdate(nextProps: Readonly<ComponentProps>): boolean {
        const currentItem = this.props.item
        const nextItem = nextProps.item

        return !_.isEqual(currentItem, nextItem)
    }

    public render(): React.ReactNode {
        const { item } = this.props

        return (
            <TableRow hover={true}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.firstName ?? '-'}</TableCell>
                <TableCell>{item.lastName ?? '-'}</TableCell>
                <TableCell>
                    {item.allowAddingArticles && strings.addingArticles.allowed}
                    {!item.allowAddingArticles && strings.addingArticles.dissalowed}
                </TableCell>
                <TableCell>
                    {item.allowAddingArticles && (
                        <Tooltip title={strings.button.disallow}>
                            <IconButton onClick={this.onDisallowClick}>
                                <LockIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {!item.allowAddingArticles && (
                        <Tooltip title={strings.button.allow}>
                            <IconButton onClick={this.onAllowClick}>
                                <LockOpenIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </TableCell>
            </TableRow>
        )
    }
    //#endregion

    //#region Private
    private onAllowClick = (): void => {
        const { item, onAllowAddingArticlesChanged } = this.props
        onAllowAddingArticlesChanged(item, true)
    }

    private onDisallowClick = (): void => {
        const { item, onAllowAddingArticlesChanged } = this.props
        onAllowAddingArticlesChanged(item, false)
    }
    //#endregion
}

export default React.memo(UserTableRow)
