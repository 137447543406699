import * as Model from './response'
import * as Request from './request'
import client from './client'

interface Params {
    limit: undefined | number
    offset: undefined | number
}

const authToken = async (username: string, password: string): Promise<Model.AccessToken> => {
    const data = {
        password,
        username,
    }
    const response = await client.post<Model.AccessToken>('oauth2/token', data)

    return response.data
}

//#region Category
const categoriesList = async (): Promise<Model.Category[]> => {
    const response = await client.get<Model.Category[]>('categories')

    return response.data
}

const categoriesDetails = async (categoryId: number): Promise<Model.Category> => {
    const response = await client.get<Model.Category>(`categories/${categoryId}`)

    return response.data
}

const categoriesCreate = async (data: Request.UpdateCategoryRequest): Promise<Model.Category> => {
    const response = await client.post<Model.Category>('categories', data)

    return response.data
}

const categoriesUpdate = async (categoryId: number, data: Request.UpdateCategoryRequest): Promise<Model.Category> => {
    const response = await client.put<Model.Category>(`categories/${categoryId}`, data)

    return response.data
}

const categoriesDelete = async (categoryId: number): Promise<void> => {
    const response = await client.delete<void>(`categories/${categoryId}`)

    return response.data
}
//#endregion

//#region Article
const articlesList = async (limit: number | null = null, offset: number | null = null): Promise<Model.Article[]> => {
    const params: Params = {
        limit: undefined,
        offset: undefined,
    }

    if (limit !== null) {
        params.limit = limit
    }

    if (offset !== null) {
        params.offset = offset
    }

    const response = await client.get<Model.Article[]>('articles', { params })

    return response.data
}

const articlesLobby = async (limit: number | null = null, offset: number | null = null): Promise<Model.Article[]> => {
    const params: Params = {
        limit: undefined,
        offset: undefined,
    }

    if (limit !== null) {
        params.limit = limit
    }

    if (offset !== null) {
        params.offset = offset
    }

    const response = await client.get<Model.Article[]>('articles/lobby', { params })

    return response.data
}

const articlesDetails = async (articleId: number): Promise<Model.Article> => {
    const response = await client.get<Model.Article>(`articles/${articleId}`)

    return response.data
}

const articlesPhoto = (articleId: number, size: string, filename: string): string => {
    return `${process.env.REACT_APP_API_URL}photos/articles/${articleId}/photo/${size}/${filename}`
}

const articlesCreateArticle = async (data: Request.CreateArticleRequest): Promise<Model.Article> => {
    const response = await client.post<Model.Article>('articles', data)

    return response.data
}

const articlesCreatePhoto = async (data: Request.CreatePhotoArticleRequest): Promise<Model.Article> => {
    const response = await client.post<Model.Article>('articles/photos', data)

    return response.data
}

const articlesUpdate = async (articleId: number, data: Request.UpdateArticleRequest): Promise<Model.Article> => {
    const response = await client.put<Model.Article>(`articles/${articleId}`, data)

    return response.data
}

const articlesAccept = async (articleId: number): Promise<Model.Article> => {
    const response = await client.post<Model.Article>(`articles/${articleId}/accept`)

    return response.data
}

const articlesPublish = async (articleId: number): Promise<Model.Article> => {
    const response = await client.post<Model.Article>(`articles/${articleId}/publish`)

    return response.data
}

const articlesDelete = async (articleId: number): Promise<void> => {
    const response = await client.delete<void>(`articles/${articleId}`)

    return response.data
}
//#endregion

//#region Photo
const photosAdd = async (photo: File): Promise<Model.Photo> => {
    const data = new FormData()
    data.append('photo', photo)
    const response = await client.post<Model.Photo>('photos', data)

    return response.data
}

const photosShow = (filename: string): string => {
    return `${process.env.REACT_APP_API_URL}photos/${filename}`
}
//#endregion

//#region User
const usersList = async (limit: number | null = null, offset: number | null = null): Promise<Model.UserDetails[]> => {
    const params: Params = {
        limit: undefined,
        offset: undefined,
    }

    if (limit !== null) {
        params.limit = limit
    }

    if (offset !== null) {
        params.offset = offset
    }

    const response = await client.get<Model.UserDetails[]>('users', { params })

    return response.data
}

const usersChangeAllowAddingArticles = async (
    userId: number,
    allowAddingArticles: boolean
): Promise<Model.UserDetails> => {
    if (allowAddingArticles) {
        const response = await client.post<Model.UserDetails>(`users/${userId}/addingArticles`)

        return response.data
    }

    const response = await client.delete<Model.UserDetails>(`users/${userId}/addingArticles`)

    return response.data
}
//#endregion

export const API = {
    auth: {
        token: authToken,
    },
    categories: {
        list: categoriesList,
        details: categoriesDetails,
        create: categoriesCreate,
        update: categoriesUpdate,
        delete: categoriesDelete,
    },
    articles: {
        list: articlesList,
        lobby: articlesLobby,
        details: articlesDetails,
        photo: articlesPhoto,
        createArticle: articlesCreateArticle,
        createPhoto: articlesCreatePhoto,
        update: articlesUpdate,
        publish: articlesPublish,
        accept: articlesAccept,
        delete: articlesDelete,
    },
    users: {
        list: usersList,
        changeAllowAddingArticles: usersChangeAllowAddingArticles,
    },
    photos: {
        add: photosAdd,
        show: photosShow,
    },
}
