import { DateTime } from '../DateTime'

export class CreateArticleRequest {
    constructor(
        readonly url: string,
        readonly description: string | null,
        readonly categories: number[],
        readonly pushTitle: string,
        readonly sendPushNotification: boolean,
        readonly publicationDate: DateTime
    ) {}
}
