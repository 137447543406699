import { AccessToken } from '../api/response'
import { Reducer } from './appStateContext'

enum AuthActionType {
    LogInUser = 'LogInUser',
    LogOutUser = 'LogOutUser',
}

interface LogInUserAction {
    type: AuthActionType.LogInUser
    token: AccessToken
}

interface LogOutUserAction {
    type: AuthActionType.LogOutUser
}

type AuthAction = LogInUserAction | LogOutUserAction

export const logInUser = (token: AccessToken): LogInUserAction => ({
    type: AuthActionType.LogInUser,
    token,
})
export const logOutUser = (): LogOutUserAction => ({ type: AuthActionType.LogOutUser })

type AuthState = AccessToken | null

export const authReducer: Reducer<AuthState, AuthAction> = (state: AuthState, action: AuthAction): AuthState => {
    switch (action.type) {
        case AuthActionType.LogInUser: {
            return action.token
        }
        case AuthActionType.LogOutUser: {
            return null
        }
        default: {
            return state
        }
    }
}
