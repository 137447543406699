import { Grid, Typography } from '@material-ui/core'
import React from 'react'

const strings = {
    error: {
        unauthorized: '401 - Brak dostępu',
    },
}

const UnauthorizedPage = (): React.ReactElement => (
    <Grid
        container={true}
        justifyContent="center"
    >
        <Grid item={true}>
            <Typography variant="h5">{strings.error.unauthorized}</Typography>
        </Grid>
    </Grid>
)

export default UnauthorizedPage
