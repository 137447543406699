import {
    Button,
    FormControl,
    FormGroup,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputLabel,
    Snackbar,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { createStyles, withStyles, WithStyles } from '@material-ui/styles'
import _ from 'lodash'
import React from 'react'
import Validator from 'validator'
import { nameofFactory } from '../../utils/nameofFactory'

//#region UI
const strings = {
    button: {
        login: 'Zaloguj',
    },
    error: {
        loginFailed: 'Logowanie zakończone niepowodzeniem',
        passwordIsRequired: 'Hasło jest wymagane',
        usernameIsRequired: 'Login jest wymagany',
    },
    label: {
        password: 'Hasło',
        username: 'Login',
    },
    placeholder: {
        password: 'Podaj hasło',
        username: 'Podaj login',
    },
}

const styles = createStyles({
    container: {
        width: 310,
    },
    loginButton: {
        fontWeight: 400,
        letterSpacing: 1,
    },
    loginButtonContainer: {
        marginTop: 16,
    },
})
//#endregion

//#region Form
enum FormFieldNames {
    UserName = 'username',
    Password = 'password',
}

interface FormFields {
    username: string
    password: string
}

interface FormErrors {
    username?: string
    password?: string
    form?: string
}
//#endregion

//#region Props & State
interface OwnProps {
    readonly submit: (username: string, password: string) => Promise<void>
}

type ComponentProps = WithStyles<typeof styles> & OwnProps

interface ComponentState {
    fields: FormFields
    errors: FormErrors
    loading: boolean
}

const initialState: ComponentState = {
    errors: {},
    fields: {
        username: '',
        password: '',
    },
    loading: false,
}
//#endregion

class LoginForm extends React.Component<ComponentProps, ComponentState> {
    private usernameInput: React.RefObject<HTMLInputElement>
    private passwordInput: React.RefObject<HTMLInputElement>

    constructor(props: ComponentProps) {
        super(props)

        this.usernameInput = React.createRef<HTMLInputElement>()
        this.passwordInput = React.createRef<HTMLInputElement>()
        this.state = initialState
    }

    //#region Lifecycle
    public shouldComponentUpdate(_nextProps: Readonly<ComponentProps>, nextState: Readonly<ComponentState>): boolean {
        return !_.isEqual(this.state, nextState)
    }

    public render(): React.ReactNode {
        const { fields, errors, loading } = this.state
        const { classes } = this.props

        return (
            <Grid
                container={true}
                justifyContent="center"
                spacing={0}
            >
                <Grid
                    item={true}
                    className={classes.container}
                >
                    <Snackbar
                        anchorOrigin={{
                            horizontal: 'center',
                            vertical: 'bottom',
                        }}
                        open={!!errors.form}
                        autoHideDuration={6000}
                        onClose={this.onFormErrorClose}
                        message={<span>{errors.form}</span>}
                        action={[
                            <IconButton
                                key="close"
                                color="inherit"
                                onClick={this.onFormErrorClose}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />

                    <form onSubmit={this.onFormSubmit}>
                        <FormGroup>
                            <FormControl
                                error={!!errors.username}
                                margin="none"
                            >
                                <InputLabel>{strings.label.username}</InputLabel>
                                <Input
                                    id={FormFieldNames.UserName}
                                    inputRef={this.usernameInput}
                                    name={FormFieldNames.UserName}
                                    onChange={this.onFieldChange}
                                    placeholder={strings.placeholder.username}
                                    type="text"
                                    value={fields.username}
                                />
                                <FormHelperText>{errors.username}</FormHelperText>
                            </FormControl>

                            <FormControl
                                error={!!errors.password}
                                margin="normal"
                            >
                                <InputLabel>{strings.label.password}</InputLabel>
                                <Input
                                    id={FormFieldNames.Password}
                                    inputRef={this.passwordInput}
                                    name={FormFieldNames.Password}
                                    onChange={this.onFieldChange}
                                    placeholder={strings.placeholder.password}
                                    type="password"
                                    value={fields.password}
                                />
                                <FormHelperText>{errors.password}</FormHelperText>
                            </FormControl>
                        </FormGroup>

                        <Grid
                            container={true}
                            spacing={2}
                            className={classes.loginButtonContainer}
                            justifyContent="flex-end"
                        >
                            <Grid item={true}>
                                <Button
                                    classes={{ label: classes.loginButton }}
                                    color="primary"
                                    disabled={loading}
                                    type="submit"
                                    variant="contained"
                                >
                                    {strings.button.login}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        )
    }
    //#endregion

    //#region Private
    private onFormErrorClose = (): void => {
        const nameof = nameofFactory<FormErrors>()
        const errors: FormErrors = _.omit(this.state.errors, nameof('form'))
        this.setState({ errors })
    }

    private onFieldChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            fields: { ...this.state.fields, [event.target.name]: event.target.value },
        })
    }

    private onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault()

        const errors: FormErrors = this.validateFormFields(this.state.fields)
        this.setState({ errors })

        if (_.isEmpty(errors)) {
            const { username, password } = this.state.fields
            this.setState({ loading: true })

            try {
                await this.props.submit(username, password)
            } catch (error) {
                if (error === null) {
                    this.setState({
                        loading: false,
                    })
                } else {
                    this.setState({
                        errors: { form: strings.error.loginFailed },
                        loading: false,
                    })
                }
            }
        } else if (!!errors.username && this.usernameInput.current !== null) {
            this.usernameInput.current.focus()
        } else if (!!errors.password && this.passwordInput.current !== null) {
            this.passwordInput.current.focus()
        }
    }

    private validateFormFields(fields: FormFields): FormErrors {
        const errors: FormErrors = {}

        if (Validator.isEmpty(fields.username)) {
            errors.username = strings.error.usernameIsRequired
        }

        if (Validator.isEmpty(fields.password)) {
            errors.password = strings.error.passwordIsRequired
        }

        return errors
    }
    //#endregion
}

export default withStyles(styles)(LoginForm)
