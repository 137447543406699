import { nameofFactory } from '../../utils/nameofFactory'

export interface ErrorResponse {
    readonly code: string
    readonly message: string
}

export function isErrorResponse(object: unknown): object is ErrorResponse {
    const nameof = nameofFactory<ErrorResponse>()

    return typeof object === 'object' && object !== null && nameof('code') in object && nameof('message') in object
}
