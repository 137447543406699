import { createTheme } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import React from 'react'
import { AppStateProvider } from '../../state/appStateContext'
import Routes from '../../routes/Routes'
import Loading from './Loading'
import MessageSnackbar from './MessageSnackbar'
import ErrorDialog from './ErrorDialog'

const theme = createTheme({})

const App = (): React.ReactElement => (
    <ThemeProvider theme={theme}>
        <AppStateProvider>
            <Routes />
            <Loading />
            <MessageSnackbar />
            <ErrorDialog />
        </AppStateProvider>
    </ThemeProvider>
)

export default App
