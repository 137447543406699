import { ErrorResponse } from '../api/response'
import { Reducer } from './appStateContext'

enum ErrorActionType {
    ShowError = 'ShowError',
    ClearError = 'ClearError',
}

interface ShowErrorAction {
    type: ErrorActionType.ShowError
    error: ErrorResponse
}

interface ClearErrorAction {
    type: ErrorActionType.ClearError
}

type ErrorAction = ShowErrorAction | ClearErrorAction

export const showError = (error: ErrorResponse): ShowErrorAction => ({
    type: ErrorActionType.ShowError,
    error,
})
export const clearError = (): ClearErrorAction => ({ type: ErrorActionType.ClearError })

type ErrorState = ErrorResponse | null

export const errorReducer: Reducer<ErrorState, ErrorAction> = (state: ErrorState, action: ErrorAction): ErrorState => {
    switch (action.type) {
        case ErrorActionType.ShowError: {
            return action.error
        }
        case ErrorActionType.ClearError: {
            return null
        }
        default: {
            return state
        }
    }
}
