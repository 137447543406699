import {
    CircularProgress,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Tooltip,
} from '@material-ui/core'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import { API } from '../../api/api'
import { Article } from '../../api/response'
import withAppCanvas from '../../components/AppCanvas'
import { MenuItemType } from '../../model/MenuItemType'
import { routesDetails } from '../../routes/routesDetails'
import { AppStateContext } from '../../state/appStateContext'
import { changeMenuItem } from '../../state/menuItemActions'
import { showMessage } from '../../state/messageActions'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { setTitle } from '../../state/titleActions'
import AddIcon from '@material-ui/icons/Add'
import ArticleTableRow from './ArticleTableRow'
import { BottomScrollListener } from 'react-bottom-scroll-listener'

const strings = {
    button: {
        create: 'Utwórz',
    },
    column: {
        id: 'Id',
        photo: 'Zdjęcie',
        title: 'Tytuł',
        actions: 'Akcje',
    },
    error: {
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
    },
}

//#region Props & State
interface ComponentProps extends RouteComponentProps {}

interface ComponentState {
    items: Article[]
    loading: boolean
    loadMore: boolean
}

const initialState: ComponentState = {
    items: [],
    loading: false,
    loadMore: true,
}
//#endregion

class ArticlesPage extends React.Component<ComponentProps, ComponentState> {
    static contextType = AppStateContext
    context!: React.ContextType<typeof AppStateContext>

    constructor(props: ComponentProps) {
        super(props)

        this.state = initialState
    }

    //#region Lifecycle
    public componentDidMount(): void {
        const { state, dispatch } = this.context
        const { menuItem, title } = state

        if (menuItem !== MenuItemType.Articles) {
            dispatch(changeMenuItem(MenuItemType.Articles))
        }

        if (title !== MenuItemType.Articles) {
            dispatch(setTitle(MenuItemType.Articles))
        }

        this.load()
    }

    public render(): React.ReactNode {
        const { loading, items } = this.state

        if (loading && items.length === 0) {
            return (
                <Grid
                    container={true}
                    justifyContent="center"
                >
                    <CircularProgress />
                </Grid>
            )
        }

        return (
            <BottomScrollListener
                onBottom={this.onDocumentBottom}
                offset={300}
                debounce={100}
                debounceOptions={{ leading: false, trailing: true }}
                triggerOnNoScroll={false}
            >
                <Paper elevation={2}>
                    <Toolbar>
                        <Tooltip title={strings.button.create}>
                            <IconButton onClick={this.onCreateClick}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                    <Table size="small">
                        <colgroup>
                            <col width="25%" />
                            <col width="10%" />
                            <col width="50%" />
                            <col width="15%" />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell>{strings.column.photo}</TableCell>
                                <TableCell>{strings.column.id}</TableCell>
                                <TableCell>{strings.column.title}</TableCell>
                                <TableCell>{strings.column.actions}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{items.map(this.renderItem)}</TableBody>
                    </Table>
                </Paper>
            </BottomScrollListener>
        )
    }
    //#endregion

    //#region Private
    private async load(): Promise<void> {
        const { items, loadMore, loading } = this.state

        if (loading || !loadMore) {
            return
        }

        this.setState({ loading: true })

        try {
            const response = await API.articles.list(20, this.state.items.length)
            const articles = items.concat(response)
            this.setState({ loading: false, items: articles, loadMore: response.length > 0 })
        } catch (error) {
            this.setState({ loading: false })
        }
    }

    private onDocumentBottom = (): void => {
        this.load()
    }

    private onItemDeleteClick = async (item: Article): Promise<void> => {
        const { dispatch } = this.context

        try {
            dispatch(setInProgress())
            await API.articles.delete(item.id)
            dispatch(setSuccess())

            const { items } = this.state
            this.setState({ items: items.filter((value) => value.id !== item.id) })
        } catch (error) {
            dispatch(setFailure())

            if (error === null) {
                dispatch(showMessage(strings.error.unknown))
            }
        }
    }

    private onCreateClick = (): void => {
        const { history } = this.props
        history.push(routesDetails.authenticated.article.to(0))
    }

    private onItemEditClick = (item: Article): void => {
        const { history } = this.props
        history.push(routesDetails.authenticated.article.to(item.id))
    }

    private onItemAcceptClick = async (): Promise<void> => {
        // do nothing
    }

    private renderItem = (item: Article): React.ReactNode => {
        return (
            <ArticleTableRow
                key={item.id}
                item={item}
                onEditItem={this.onItemEditClick}
                onAcceptItem={this.onItemAcceptClick}
                onDeleteItem={this.onItemDeleteClick}
            />
        )
    }
    //#endregion
}

export default withAppCanvas(ArticlesPage)
