import _ from 'lodash'
import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import GuestRoute from '../routes/GuestRoute'
import AuthenticatedRoute from './AuthenticatedRoute'
import { routesDetails } from './routesDetails'

const Routes: React.FC = () => (
    <BrowserRouter>
        <Switch>
            {_.values(routesDetails.authenticated).map((route, index) => (
                <AuthenticatedRoute
                    key={index}
                    exact={true}
                    strict={true}
                    sensitive={true}
                    path={route.path}
                    Component={route.component}
                />
            ))}
            {_.values(routesDetails.guest).map((route, index) => (
                <GuestRoute
                    key={index}
                    exact={true}
                    strict={true}
                    sensitive={true}
                    path={route.path}
                    Component={route.component}
                />
            ))}
            <Route
                exact={true}
                strict={true}
                sensitive={true}
                path={routesDetails.error.unauthorized.path}
                component={routesDetails.error.unauthorized.component}
            />
            <Route component={routesDetails.error.notFound.component} />
        </Switch>
    </BrowserRouter>
)

export default Routes
