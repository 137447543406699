import React from 'react'
import { initializeClient, configureClient } from '../api/client'
import { AccessToken, ErrorResponse } from '../api/response'
import { MenuItemType } from '../model/MenuItemType'
import { authReducer } from './authActions'
import { errorReducer } from './errorActions'
import localStore from './LocalStore'
import { menuItemReducer } from './menuItemActions'
import { messageReducer } from './messageActions'
import { progressReducer } from './progressActions'
import { titleReducer } from './titleActions'

export interface Action {
    type: string
}

export type Reducer<S, A extends Action> = (state: S, action: A) => S

export interface AppState {
    readonly auth: AccessToken | null
    readonly progress: number
    readonly error: ErrorResponse | null
    readonly menuItem: MenuItemType | null
    readonly message: string | null
    readonly title: string | null
}

const initialState: AppState = {
    auth: localStore.accessToken(),
    progress: 0,
    error: null,
    menuItem: null,
    message: null,
    title: null,
}

const combineReducers =
    (reducers: any) =>
    (state: any, action: any): any =>
        Object.keys(reducers).reduce(
            (acc, prop) => ({
                ...acc,
                [prop]: reducers[prop](acc[prop], action),
            }),
            state
        )

const rootReducer = combineReducers({
    auth: authReducer,
    progress: progressReducer,
    error: errorReducer,
    menuItem: menuItemReducer,
    message: messageReducer,
    title: titleReducer,
})

interface AppStateProviderProps {
    children: React.ReactNode
}

type AppStateDispatch = (action: Action) => void

export interface AppStateContextType {
    state: AppState
    dispatch: AppStateDispatch
}

// @ts-ignore
export const AppStateContext = React.createContext<AppStateContextType>(undefined)
AppStateContext.displayName = 'AppStateContext'

export function AppStateProvider({ children }: AppStateProviderProps): JSX.Element {
    const [state, dispatch] = React.useReducer(rootReducer, initialState)
    const value = React.useMemo<AppStateContextType>(() => ({ state, dispatch }), [state])

    React.useMemo(() => {
        initializeClient()
    }, [])
    React.useMemo(() => {
        configureClient(value)
    }, [value.state.auth])

    return <AppStateContext.Provider value={value}>{children}</AppStateContext.Provider>
}

export function useAppStateContext(): AppStateContextType {
    const context = React.useContext(AppStateContext)
    if (context === undefined) {
        throw new Error('useAppStateContext must be used within a Provider')
    }

    return context
}
