import React from 'react'
import _ from 'lodash'
import { RouteComponentProps } from 'react-router'
import Validator from 'validator'
import { API } from '../../api/api'
import withAppCanvas from '../../components/AppCanvas'
import { MenuItemType } from '../../model/MenuItemType'
import { AppStateContext } from '../../state/appStateContext'
import { changeMenuItem } from '../../state/menuItemActions'
import { setTitle } from '../../state/titleActions'
import { CreateArticleRequest, CreatePhotoArticleRequest, UpdateArticleRequest } from '../../api/request'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { routesDetails } from '../../routes/routesDetails'
import { showMessage } from '../../state/messageActions'
import { Article, Category } from '../../api/response'
import { DateTime } from '../../api/DateTime'
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Dayjs } from 'dayjs'
import locale from 'dayjs/locale/pl'
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    Typography,
    TextField,
    TextFieldProps,
    ButtonGroup,
    FormHelperText,
    Switch,
} from '@mui/material'
import Dropzone, { DropzoneState } from 'react-dropzone'

//#region UI
const strings = {
    button: {
        save: 'Zapisz',
        publish: 'Opublikuj',
        cancel: 'Anuluj',
        article: 'Artykuł',
        photo: 'Zdjęcie',
        accept: 'Zaakceptuj',
        reject: 'Odrzuć',
    },
    error: {
        urlLength: 'Wymagana wartość w przedziale <2, 255> znaków',
        urlInvalid: 'Proszę podać poprawny adres url',
        urlRequired: 'Adres url jest wymagany',
        titleRequired: 'Tytuł jest wymagany',
        titleLength: 'Wymagana wartość w przedziale <2, 255> znaków',
        photoFilenameRequired: 'Zdjęcie jest wymagane',
        pushTitleRequired: 'Tytuł powiadomienia jest wymagany',
        pushTitleLength: 'Wymagana wartość w przedziale <2, 255> znaków',
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
        photo: 'Zapis zdjęcia zakończony niepowodzeniem, spróbuj ponownie',
    },
    label: {
        url: 'Adres strony',
        title: 'Tytuł',
        description: 'Opcjonalny opis',
        categories: 'Kategorie',
        pushTitle: 'Tytuł powiadomienia push',
        publicationDate: 'Data publikacji',
        uploadPhoto: 'Wybierz zdjęcie',
        sendPushNotification: 'Powiadomienie push',
    },
    message: {
        added: 'Nowy artykuł został dodany',
        edited: 'Artykuł został zaktualizowany',
        published: 'Artykuł został opublikowany',
    },
    pushTitle: {
        breaking: '🔵 Z ostatniej chwili',
        urgent: '🔴 Pilne!',
        share: '➡️ Udostepnij.pl',
    },
}
//#endregion

//#region Form
enum FormFieldNames {
    Url = 'url',
    Title = 'title',
    Description = 'description',
    Categories = 'categories',
    PushTitle = 'pushTitle',
    SendPushNotification = 'sendPushNotification',
    PublicationDate = 'publicationDate',
    PhotoFilename = 'photoFilename',
}

interface FormFields {
    url: string | null
    title: string
    description: string
    categories: number[]
    pushTitle: string
    sendPushNotification: boolean
    publicationDate: DateTime
    photoFilename: string | null
}

interface FormErrors {
    url?: string
    title?: string
    pushTitle?: string
    publicationDate?: string
    photoFilename?: string
}
//#endregion

//#region Props & State
interface RouteParams {
    articleId: string
}
type ComponentProps = RouteComponentProps<RouteParams>

enum ViewType {
    Article,
    Photo,
}

interface ComponentState {
    loading: boolean
    savingPhoto: boolean
    loaded: boolean
    errors: FormErrors
    fields: FormFields
    article: Article | null
    categories: Category[] | null
    viewType: ViewType
}

const initialState: ComponentState = {
    errors: {},
    fields: {
        url: null,
        title: '',
        description: '',
        categories: [],
        pushTitle: strings.pushTitle.breaking,
        sendPushNotification: false,
        publicationDate: new DateTime(),
        photoFilename: null,
    },
    loaded: false,
    loading: false,
    savingPhoto: false,
    article: null,
    categories: null,
    viewType: ViewType.Article,
}
//#endregion

class ArticleDetailsPage extends React.Component<ComponentProps, ComponentState> {
    static contextType = AppStateContext
    context!: React.ContextType<typeof AppStateContext>

    private articleId: number

    constructor(props: ComponentProps) {
        super(props)

        this.state = initialState

        const { articleId } = this.props.match.params
        this.articleId = parseInt(articleId, 10)
    }

    //#region Lifecycle
    public componentDidMount(): void {
        const { state, dispatch } = this.context
        const { menuItem, title } = state

        if (menuItem !== MenuItemType.Articles) {
            dispatch(changeMenuItem(MenuItemType.Articles))
        }

        if (title !== MenuItemType.Articles) {
            dispatch(setTitle(MenuItemType.Articles))
        }

        this.load()
    }

    public render(): React.ReactNode {
        const { loading, loaded, errors, fields, article, categories, savingPhoto, viewType } = this.state

        if (loading) {
            return (
                <Grid
                    container={true}
                    justifyContent="center"
                >
                    <CircularProgress />
                </Grid>
            )
        }

        if (!loaded) {
            return (
                <Grid
                    container={true}
                    justifyContent="center"
                >
                    <Typography color="error">{strings.error.unknown}</Typography>
                </Grid>
            )
        }

        const buttons = (
            <React.Fragment>
                <Grid item={true}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.onSaveClick}
                    >
                        {(article === null || !article.lobby) && strings.button.save}
                        {article !== null && article.lobby && strings.button.accept}
                    </Button>
                </Grid>

                {article !== null && article.lobby === true && (
                    <Grid item={true}>
                        <Button
                            color="error"
                            variant="contained"
                            onClick={this.onDeleteClick}
                        >
                            {strings.button.reject}
                        </Button>
                    </Grid>
                )}

                {article !== null && article.isPublished === false && article.lobby === false && (
                    <Grid item={true}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={this.onPublishClick}
                        >
                            {strings.button.publish}
                        </Button>
                    </Grid>
                )}
            </React.Fragment>
        )

        return (
            <React.Fragment>
                <Grid
                    container={true}
                    spacing={1}
                    direction="row"
                    marginBottom={10}
                >
                    {article === null && (
                        <Grid
                            item={true}
                            xs={12}
                        >
                            <ButtonGroup variant="contained">
                                <Button
                                    variant={viewType === ViewType.Article ? 'contained' : 'outlined'}
                                    onClick={this.onViewTypeArticleClick}
                                >
                                    {strings.button.article}
                                </Button>
                                <Button
                                    variant={viewType === ViewType.Photo ? 'contained' : 'outlined'}
                                    onClick={this.onViewTypePhotoClick}
                                >
                                    {strings.button.photo}
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    )}

                    {article !== null && (
                        <Grid
                            item={true}
                            xs={12}
                        >
                            {!!article.url && (
                                <a
                                    href={article.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ color: 'black', textDecoration: 'none' }}
                                >
                                    <Typography variant="h3">{article.title}</Typography>
                                </a>
                            )}
                            {!article.url && <Typography variant="h3">{article.title}</Typography>}
                        </Grid>
                    )}
                    {article !== null && (
                        <Grid
                            item={true}
                            xs={12}
                        >
                            {!!article.url && (
                                <a
                                    href={article.url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        alt={article.title}
                                        src={API.articles.photo(article.id, '512', article.photoFilename)}
                                    />
                                </a>
                            )}
                            {!article.url && (
                                <img
                                    alt={article.title}
                                    src={API.articles.photo(article.id, '512', article.photoFilename)}
                                />
                            )}
                        </Grid>
                    )}

                    {buttons}

                    {((this.articleId === 0 && viewType === ViewType.Article) || !!fields.url) && (
                        <Grid
                            item={true}
                            xs={12}
                        >
                            <TextField
                                error={errors.url !== undefined}
                                helperText={errors.url}
                                id={FormFieldNames.Url}
                                name={FormFieldNames.Url}
                                value={fields.url}
                                multiline={false}
                                required={true}
                                inputProps={{ maxLength: 255 }}
                                fullWidth={true}
                                label={strings.label.url}
                                margin="none"
                                onChange={this.onTextFieldChange}
                                disabled={this.articleId !== 0}
                            />
                        </Grid>
                    )}

                    {this.articleId === 0 && viewType === ViewType.Photo && (
                        <Dropzone
                            disabled={savingPhoto}
                            multiple={false}
                            accept={{
                                'image/*': ['.png', '.jpg', '.jpeg'],
                            }}
                            onDropAccepted={this.onFilesAccepted}
                        >
                            {(state: DropzoneState): JSX.Element => (
                                <Grid
                                    container={true}
                                    justifyContent="center"
                                    alignItems="center"
                                    alignContent="center"
                                    direction="row"
                                    spacing={1}
                                    style={{
                                        borderColor: '#666666',
                                        borderRadius: 5,
                                        borderStyle: 'dashed',
                                        borderWidth: 2,
                                        marginTop: 10,
                                        minHeight: 150,
                                        marginLeft: 10,
                                        position: 'relative',
                                        width: '100%',
                                    }}
                                    {...state.getRootProps()}
                                >
                                    <input {...state.getInputProps()} />
                                    <Grid
                                        item={true}
                                        xs={12}
                                    >
                                        {!fields.photoFilename && <Typography>{strings.label.uploadPhoto}</Typography>}
                                        {!!fields.photoFilename && (
                                            <img
                                                alt=""
                                                style={{ maxHeight: 300 }}
                                                src={API.photos.show(fields.photoFilename)}
                                            />
                                        )}
                                    </Grid>
                                    {savingPhoto && (
                                        <CircularProgress
                                            style={{ position: 'absolute' }}
                                            size={48}
                                        />
                                    )}
                                </Grid>
                            )}
                        </Dropzone>
                    )}
                    {errors.photoFilename !== undefined && (
                        <Grid
                            item={true}
                            xs={12}
                        >
                            <FormHelperText error={true}>{errors.photoFilename}</FormHelperText>
                        </Grid>
                    )}

                    {(this.articleId !== 0 || viewType === ViewType.Photo) && (
                        <Grid
                            item={true}
                            xs={12}
                        >
                            <TextField
                                error={errors.title !== undefined}
                                helperText={errors.title}
                                id={FormFieldNames.Title}
                                name={FormFieldNames.Title}
                                value={fields.title}
                                multiline={false}
                                required={true}
                                inputProps={{ maxLength: 255 }}
                                fullWidth={true}
                                label={strings.label.title}
                                margin="none"
                                onChange={this.onTextFieldChange}
                            />
                        </Grid>
                    )}

                    <Grid
                        item={true}
                        xs={12}
                    >
                        <TextField
                            id={FormFieldNames.Description}
                            name={FormFieldNames.Description}
                            value={fields.description}
                            multiline={true}
                            required={false}
                            fullWidth={true}
                            label={strings.label.description}
                            margin="none"
                            onChange={this.onTextFieldChange}
                        />
                    </Grid>

                    <React.Fragment>
                        <Grid
                            item={true}
                            xs={12}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            id={FormFieldNames.SendPushNotification}
                                            name={FormFieldNames.SendPushNotification}
                                            checked={fields.sendPushNotification}
                                            onChange={this.onSwitchChange}
                                        />
                                    }
                                    label={strings.label.sendPushNotification}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item={true}
                            xs={12}
                        >
                            <TextField
                                error={errors.pushTitle !== undefined}
                                helperText={errors.pushTitle}
                                id={FormFieldNames.PushTitle}
                                name={FormFieldNames.PushTitle}
                                value={fields.pushTitle}
                                multiline={false}
                                required={true}
                                inputProps={{ maxLength: 255 }}
                                fullWidth={true}
                                label={strings.label.pushTitle}
                                margin="none"
                                onChange={this.onTextFieldChange}
                            />
                        </Grid>
                        <Grid
                            item={true}
                            xs={12}
                        >
                            <Button
                                variant="text"
                                style={{ textTransform: 'none' }}
                                onClick={(): void =>
                                    this.setState({
                                        fields: { ...this.state.fields, pushTitle: strings.pushTitle.breaking },
                                    })
                                }
                            >
                                {strings.pushTitle.breaking}
                            </Button>
                            <Button
                                variant="text"
                                style={{ textTransform: 'none' }}
                                onClick={(): void =>
                                    this.setState({
                                        fields: { ...this.state.fields, pushTitle: strings.pushTitle.urgent },
                                    })
                                }
                            >
                                {strings.pushTitle.urgent}
                            </Button>
                            <Button
                                variant="text"
                                style={{ textTransform: 'none' }}
                                onClick={(): void =>
                                    this.setState({
                                        fields: { ...this.state.fields, pushTitle: strings.pushTitle.share },
                                    })
                                }
                            >
                                {strings.pushTitle.share}
                            </Button>
                        </Grid>
                    </React.Fragment>

                    <Grid
                        item={true}
                        xs={12}
                    >
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            locale={locale}
                        >
                            <MobileDateTimePicker
                                renderInput={(props: TextFieldProps): React.ReactElement => (
                                    <TextField
                                        onBlur={this.onTextFieldBlur}
                                        {...props}
                                    />
                                )}
                                label={strings.label.publicationDate}
                                inputFormat="YYYY-MM-DD HH:mm"
                                mask="____-__-__ __:__"
                                value={fields.publicationDate}
                                onChange={this.onDateTimeChange}
                                cancelText={strings.button.cancel}
                                showToolbar={true}
                            />
                        </LocalizationProvider>
                    </Grid>

                    {categories !== null && (
                        <Grid
                            item={true}
                            xs={12}
                            style={{ marginTop: 10 }}
                        >
                            <FormControl fullWidth={true}>
                                <InputLabel shrink>{strings.label.categories}</InputLabel>
                                <FormGroup style={{ marginTop: 16 }}>
                                    {categories.map((category: Category) => (
                                        <FormControlLabel
                                            key={category.id}
                                            control={
                                                <Checkbox
                                                    key={category.id}
                                                    checked={fields.categories.includes(category.id)}
                                                    onChange={this.onCheckboxChange}
                                                    name={category.id.toString()}
                                                />
                                            }
                                            label={category.name}
                                        />
                                    ))}
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    )}

                    {buttons}
                </Grid>
            </React.Fragment>
        )
    }
    //#endregion

    //#region Private
    private load = async (): Promise<void> => {
        if (this.articleId === 0) {
            this.loadCategories()
        } else {
            try {
                this.setState({ loading: true })
                const response = await API.articles.details(this.articleId)
                this.setState({
                    article: response,
                    fields: {
                        url: response.url,
                        title: response.title,
                        description: response.description ?? '',
                        categories: response.categories.map((category: Category) => category.id),
                        pushTitle: response.pushTitle,
                        sendPushNotification: response.sendPushNotification,
                        publicationDate: response.publicationDate,
                        photoFilename: response.photoFilename,
                    },
                })

                this.loadCategories()
            } catch (error) {
                this.setState({ loading: false })
            }
        }
    }

    private loadCategories = async (): Promise<void> => {
        try {
            this.setState({ loading: true })
            const response = await API.categories.list()
            this.setState({
                categories: response,
                loaded: true,
                loading: false,
            })
        } catch (error) {
            this.setState({ loading: false })
        }
    }

    private onViewTypeArticleClick = (): void => {
        this.setState({ viewType: ViewType.Article })
    }

    private onViewTypePhotoClick = (): void => {
        this.setState({ viewType: ViewType.Photo })
    }

    private onFilesAccepted = async (files: File[]): Promise<void> => {
        const { dispatch } = this.context

        try {
            this.setState({ savingPhoto: true })
            const response = await API.photos.add(files[0])
            this.setState({
                savingPhoto: false,
                fields: {
                    ...this.state.fields,
                    photoFilename: response.filename,
                },
            })
        } catch (error) {
            this.setState({ savingPhoto: false })

            if (error === null) {
                dispatch(showMessage(strings.error.photo))
            }
        }
    }

    private onDateTimeChange = (date: Dayjs | null): void => {
        if (date !== null) {
            this.setState({
                fields: {
                    ...this.state.fields,
                    publicationDate: new DateTime(date.toDate()),
                },
            })
        }
    }

    private onTextFieldChange = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>
    ): void => {
        this.setState({
            fields: { ...this.state.fields, [event.target.name]: event.target.value },
        })
    }

    private onTextFieldBlur = (): void => {
        this.setState({
            fields: {
                ...this.state.fields,
                publicationDate: this.state.fields.publicationDate,
            },
        })
    }

    private onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { categories } = this.state.fields
        const categoryId = parseInt(event.target.name, 10)

        if (!event.target.checked) {
            this.setState({
                fields: {
                    ...this.state.fields,
                    categories: categories.filter((value) => value !== categoryId),
                },
            })
        } else {
            this.setState({
                fields: {
                    ...this.state.fields,
                    categories: categories.concat([categoryId]),
                },
            })
        }
    }

    private onSwitchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            fields: {
                ...this.state.fields,
                [event.target.name]: event.target.checked,
            },
        })
    }

    private onPublishClick = async (): Promise<void> => {
        if (this.articleId === 0) {
            return
        }

        const { dispatch } = this.context

        try {
            dispatch(setInProgress())
            const response = await API.articles.publish(this.articleId)
            dispatch(setSuccess())
            dispatch(showMessage(strings.message.published))

            this.setState({
                article: response,
            })
        } catch (error) {
            dispatch(setFailure())

            if (error === null) {
                dispatch(showMessage(strings.error.unknown))
            }
        }
    }

    private onDeleteClick = async (): Promise<void> => {
        if (this.articleId === 0) {
            return
        }

        const { dispatch } = this.context

        try {
            dispatch(setInProgress())
            await API.articles.delete(this.articleId)
            dispatch(setSuccess())

            const { history } = this.props
            history.push(routesDetails.authenticated.lobby.path)
        } catch (error) {
            dispatch(setFailure())

            if (error === null) {
                dispatch(showMessage(strings.error.unknown))
            }
        }
    }

    private onSaveClick = async (): Promise<void> => {
        const { viewType } = this.state

        const addingUrl = this.articleId === 0 && viewType === ViewType.Article
        const addingPhoto = this.articleId === 0 && viewType === ViewType.Photo

        const errors: FormErrors = this.validateFormFields(
            this.state.fields,
            addingUrl, // adding url, so url is required
            addingPhoto, // adding photo, so filename is required
            !addingUrl // title required if not adding url
        )
        this.setState({ errors })

        if (_.isEmpty(errors)) {
            if (this.articleId === 0) {
                if (viewType === ViewType.Article) {
                    await this.createArticle()
                } else if (viewType === ViewType.Photo) {
                    await this.createPhoto()
                }
            } else {
                await this.update()
            }
        }
    }

    private createArticle = async (): Promise<void> => {
        const { dispatch } = this.context
        const { fields } = this.state
        const { history } = this.props

        let description: string | null = fields.description
        if (description.length === 0) {
            description = null
        }

        const request = new CreateArticleRequest(
            fields.url!,
            description,
            fields.categories,
            fields.pushTitle,
            fields.sendPushNotification,
            fields.publicationDate
        )

        try {
            dispatch(setInProgress())
            const response = await API.articles.createArticle(request)
            dispatch(setSuccess())
            history.replace(routesDetails.authenticated.article.to(response.id))
            this.articleId = response.id
            dispatch(showMessage(strings.message.added))

            this.setState({
                article: response,
                fields: {
                    url: response.url,
                    title: response.title,
                    description: response.description ?? '',
                    categories: response.categories.map((category: Category) => category.id),
                    pushTitle: response.pushTitle,
                    sendPushNotification: response.sendPushNotification,
                    publicationDate: response.publicationDate,
                    photoFilename: response.photoFilename,
                },
            })
        } catch (error) {
            dispatch(setFailure())

            if (error === null) {
                dispatch(showMessage(strings.error.unknown))
            }
        }
    }

    private createPhoto = async (): Promise<void> => {
        const { dispatch } = this.context
        const { fields } = this.state
        const { history } = this.props

        let description: string | null = fields.description
        if (description.length === 0) {
            description = null
        }

        const request = new CreatePhotoArticleRequest(
            fields.photoFilename!,
            fields.title,
            description,
            fields.categories,
            fields.pushTitle,
            fields.sendPushNotification,
            fields.publicationDate
        )

        try {
            dispatch(setInProgress())
            const response = await API.articles.createPhoto(request)
            dispatch(setSuccess())
            history.replace(routesDetails.authenticated.article.to(response.id))
            this.articleId = response.id
            dispatch(showMessage(strings.message.added))

            this.setState({
                article: response,
                fields: {
                    url: response.url,
                    title: response.title,
                    description: response.description ?? '',
                    categories: response.categories.map((category: Category) => category.id),
                    pushTitle: response.pushTitle,
                    sendPushNotification: response.sendPushNotification,
                    publicationDate: response.publicationDate,
                    photoFilename: response.photoFilename,
                },
            })
        } catch (error) {
            dispatch(setFailure())

            if (error === null) {
                dispatch(showMessage(strings.error.unknown))
            }
        }
    }

    private update = async (): Promise<void> => {
        const { dispatch } = this.context
        const { fields } = this.state

        let description: string | null = fields.description
        if (description.length === 0) {
            description = null
        }

        const request = new UpdateArticleRequest(
            fields.title,
            description,
            fields.categories,
            fields.pushTitle,
            fields.sendPushNotification,
            fields.publicationDate
        )

        try {
            dispatch(setInProgress())
            const response = await API.articles.update(this.articleId, request)
            dispatch(setSuccess())
            dispatch(showMessage(strings.message.edited))

            this.setState({
                article: response,
            })
        } catch (error) {
            dispatch(setFailure())

            if (error === null) {
                dispatch(showMessage(strings.error.unknown))
            }
        }
    }

    private validateFormFields(
        fields: FormFields,
        checkUrl: boolean,
        checkPhotoFilename: boolean,
        checkTitle: boolean
    ): FormErrors {
        const errors: FormErrors = {}

        if (checkUrl) {
            if (fields.url === null || Validator.isEmpty(fields.url)) {
                errors.url = strings.error.urlRequired
            } else if (!Validator.isLength(fields.url, { min: 2, max: 255 })) {
                errors.url = strings.error.urlLength
            } else if (!Validator.isURL(fields.url)) {
                errors.url = strings.error.urlInvalid
            }
        }

        if (checkPhotoFilename) {
            if (fields.photoFilename === null || Validator.isEmpty(fields.photoFilename)) {
                errors.photoFilename = strings.error.photoFilenameRequired
            }
        }

        if (checkTitle) {
            if (Validator.isEmpty(fields.title)) {
                errors.title = strings.error.titleRequired
            } else if (!Validator.isLength(fields.title, { min: 2, max: 255 })) {
                errors.title = strings.error.titleLength
            }
        }

        if (Validator.isEmpty(fields.pushTitle)) {
            errors.pushTitle = strings.error.pushTitleRequired
        } else if (!Validator.isLength(fields.pushTitle, { min: 2, max: 255 })) {
            errors.pushTitle = strings.error.pushTitleLength
        }

        return errors
    }
    //#endregion
}

export default withAppCanvas(ArticleDetailsPage)
