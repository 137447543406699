import React from 'react'
import { TableCell, TableRow, Tooltip, IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import { Article } from '../../api/response'
import _ from 'lodash'
import { API } from '../../api/api'

const strings = {
    published: 'Opublikowano: ',
    publish: 'Publikacja: ',
    button: {
        accept: 'Zaakceptuj',
        delete: 'Usuń',
        edit: 'Edytuj',
    },
}

interface ComponentProps {
    item: Article
    onEditItem: (item: Article) => void
    onAcceptItem: (item: Article) => void
    onDeleteItem: (item: Article) => void
}

class ArticleTableRow extends React.Component<ComponentProps> {
    //#region Lifecycle
    public shouldComponentUpdate(nextProps: Readonly<ComponentProps>): boolean {
        const currentItem = this.props.item
        const nextItem = nextProps.item

        return !_.isEqual(currentItem, nextItem)
    }

    public render(): React.ReactNode {
        const { item } = this.props

        return (
            <TableRow hover={true}>
                <TableCell>
                    {!!item.url && (
                        <a
                            href={item.url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                alt={item.title}
                                src={API.articles.photo(item.id, '248', item.photoFilename)}
                            />
                        </a>
                    )}

                    {!item.url && (
                        <img
                            alt={item.title}
                            src={API.articles.photo(item.id, '248', item.photoFilename)}
                        />
                    )}
                </TableCell>
                <TableCell>{item.id}</TableCell>
                <TableCell>
                    {!!item.url && (
                        <a
                            href={item.url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {item.title}
                        </a>
                    )}
                    {!item.url && item.title}
                    <br />
                    {item.isPublished && strings.published}
                    {!item.isPublished && strings.publish}
                    <span>{item.publicationDate.toLocaleString('pl-PL')}</span>
                    <br />
                    <span>👍 {item.emojis.thumbsUp}</span>
                    &nbsp;
                    <span>👎 {item.emojis.thumbsDown}</span>
                    &nbsp;
                    <span>❤️ {item.emojis.redHeart}</span>
                    &nbsp;
                    <span>😀 {item.emojis.grinningFace}</span>
                    &nbsp;
                    <span>😪 {item.emojis.cryingFace}</span>
                    &nbsp;
                    <span>😡 {item.emojis.enragedFace}</span>
                </TableCell>
                <TableCell>
                    <Tooltip title={strings.button.edit}>
                        <IconButton onClick={this.onEditClick}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    {item.lobby && (
                        <Tooltip title={strings.button.accept}>
                            <IconButton onClick={this.onAcceptClick}>
                                <CheckIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip title={strings.button.delete}>
                        <IconButton onClick={this.onDeleteClick}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        )
    }
    //#endregion

    //#region Private
    private onEditClick = (): void => {
        const { item, onEditItem } = this.props

        onEditItem(item)
    }

    private onAcceptClick = (): void => {
        const { item, onAcceptItem } = this.props

        onAcceptItem(item)
    }

    private onDeleteClick = (): void => {
        const { item, onDeleteItem } = this.props

        onDeleteItem(item)
    }
    //#endregion
}

export default React.memo(ArticleTableRow)
