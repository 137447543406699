import HomePage from '../modules/app/HomePage'
import ArticleDetailsPage from '../modules/articles/ArticleDetailsPage'
import ArticlesPage from '../modules/articles/ArticlesPage'
import LobbyPage from '../modules/articles/LobbyPage'
import CategoriesPage from '../modules/categories/CategoriesPage'
import CategoryDetailsPage from '../modules/categories/CategoryDetailsPage'
import NotFoundPage from '../modules/error/NotFoundPage'
import UnauthorizedPage from '../modules/error/UnauthorizedPage'
import LoginPage from '../modules/login/LoginPage'
import UsersPage from '../modules/users/UsersPage'

export const routesDetails = {
    authenticated: {
        homePage: {
            component: HomePage,
            path: '/',
        },
        categories: {
            component: CategoriesPage,
            path: '/categories',
        },
        category: {
            component: CategoryDetailsPage,
            path: '/categories/:categoryId(\\d+)',
            to: (categoryId: number): string => `/categories/${categoryId}`,
        },
        articles: {
            component: ArticlesPage,
            path: '/articles',
        },
        article: {
            component: ArticleDetailsPage,
            path: '/articles/:articleId(\\d+)',
            to: (articleId: number): string => `/articles/${articleId}`,
        },
        lobby: {
            component: LobbyPage,
            path: '/articles/lobby',
        },
        users: {
            component: UsersPage,
            path: '/users',
        },
    },
    error: {
        notFound: {
            component: NotFoundPage,
            path: '/error/404',
        },
        unauthorized: {
            component: UnauthorizedPage,
            path: '/error/401',
        },
    },
    guest: {
        login: {
            component: LoginPage,
            path: '/login',
        },
    },
}
