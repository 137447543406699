import {
    CircularProgress,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Tooltip,
} from '@material-ui/core'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import { API } from '../../api/api'
import { Category } from '../../api/response'
import withAppCanvas from '../../components/AppCanvas'
import { MenuItemType } from '../../model/MenuItemType'
import { routesDetails } from '../../routes/routesDetails'
import { AppStateContext } from '../../state/appStateContext'
import { changeMenuItem } from '../../state/menuItemActions'
import { showMessage } from '../../state/messageActions'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { setTitle } from '../../state/titleActions'
import AddIcon from '@material-ui/icons/Add'
import CategoryTableRow from './CategoryTableRow'

const strings = {
    button: {
        create: 'Utwórz',
    },
    column: {
        id: 'Id',
        name: 'Nazwa',
        isDefault: 'Domyślna',
        actions: 'Akcje',
    },
    error: {
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
    },
}

//#region Props & State
interface ComponentProps extends RouteComponentProps {}

interface ComponentState {
    items: Category[]
    loading: boolean
}

const initialState: ComponentState = {
    items: [],
    loading: true,
}
//#endregion

class CategoriesPage extends React.Component<ComponentProps, ComponentState> {
    static contextType = AppStateContext
    context!: React.ContextType<typeof AppStateContext>

    constructor(props: ComponentProps) {
        super(props)

        this.state = initialState
    }

    //#region Lifecycle
    public componentDidMount(): void {
        const { state, dispatch } = this.context
        const { menuItem, title } = state

        if (menuItem !== MenuItemType.Categories) {
            dispatch(changeMenuItem(MenuItemType.Categories))
        }

        if (title !== MenuItemType.Categories) {
            dispatch(setTitle(MenuItemType.Categories))
        }

        this.load()
    }

    public render(): React.ReactNode {
        const { loading, items } = this.state

        if (loading) {
            return (
                <Grid
                    container={true}
                    justifyContent="center"
                >
                    <CircularProgress />
                </Grid>
            )
        }

        return (
            <Paper elevation={2}>
                <Toolbar>
                    <Tooltip title={strings.button.create}>
                        <IconButton onClick={this.onCreateClick}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
                <Table size="small">
                    <colgroup>
                        <col width="20%" />
                        <col width="30%" />
                        <col width="35%" />
                        <col width="15%" />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell>{strings.column.id}</TableCell>
                            <TableCell>{strings.column.name}</TableCell>
                            <TableCell>{strings.column.isDefault}</TableCell>
                            <TableCell>{strings.column.actions}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{items.map(this.renderItem)}</TableBody>
                </Table>
            </Paper>
        )
    }
    //#endregion

    //#region Private
    private async load(): Promise<void> {
        this.setState({ loading: true })

        try {
            const items = await API.categories.list()
            this.setState({ loading: false, items })
        } catch (error) {
            this.setState({ loading: false })
        }
    }

    private onItemDeleteClick = async (item: Category): Promise<void> => {
        const { dispatch } = this.context

        try {
            dispatch(setInProgress())
            await API.categories.delete(item.id)
            dispatch(setSuccess())

            const { items } = this.state
            this.setState({ items: items.filter((value) => value.id !== item.id) })
        } catch (error) {
            dispatch(setFailure())

            if (error === null) {
                dispatch(showMessage(strings.error.unknown))
            }
        }
    }

    private onCreateClick = (): void => {
        const { history } = this.props
        history.push(routesDetails.authenticated.category.to(0))
    }

    private onItemEditClick = (item: Category): void => {
        const { history } = this.props
        history.push(routesDetails.authenticated.category.to(item.id))
    }

    private renderItem = (item: Category): React.ReactNode => {
        return (
            <CategoryTableRow
                key={item.id}
                item={item}
                onEditItem={this.onItemEditClick}
                onDeleteItem={this.onItemDeleteClick}
            />
        )
    }
    //#endregion
}

export default withAppCanvas(CategoriesPage)
