import React from 'react'
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router'
import { useAppStateContext } from '../state/appStateContext'
import { routesDetails } from './routesDetails'

interface ComponentProps extends RouteProps {
    readonly [rest: string]: any
    readonly Component: React.ComponentClass<any> | React.FC<any>
}

const GuestRoute = (props: ComponentProps): React.ReactElement => {
    const { state } = useAppStateContext()
    const { Component, ...rest } = props

    const render = (renderProps: RouteComponentProps<any>): React.ReactElement => {
        if (state.auth === null) {
            return <Component {...renderProps} />
        }

        return (
            <Redirect
                to={{
                    pathname: routesDetails.authenticated.homePage.path,
                    state: { from: renderProps.location },
                }}
            />
        )
    }

    return (
        <Route
            {...rest}
            render={render}
        />
    )
}

export default React.memo(GuestRoute)
