import React from 'react'
import { RouteComponentProps } from 'react-router'
import { API } from '../../api/api'
import { AppStateContext } from '../../state/appStateContext'
import { logInUser } from '../../state/authActions'
import localStore from '../../state/LocalStore'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { routesDetails } from '../../routes/routesDetails'
import LoginForm from './LoginForm'
import LoginHeader from './LoginHeader'

type ComponentProps = RouteComponentProps

class LoginPage extends React.Component<ComponentProps> {
    static contextType = AppStateContext
    context!: React.ContextType<typeof AppStateContext>

    public render(): React.ReactNode {
        return (
            <React.Fragment>
                <LoginHeader />
                <LoginForm submit={this.submit} />
            </React.Fragment>
        )
    }

    private submit = async (username: string, password: string): Promise<void> => {
        const { dispatch } = this.context
        const { history } = this.props

        dispatch(setInProgress())

        try {
            const response = await API.auth.token(username, password)
            localStore.storeAccessToken(response)
            dispatch(logInUser(response))
            dispatch(setSuccess())
            history.push(routesDetails.authenticated.homePage.path)
        } catch (error) {
            dispatch(setFailure())

            throw error
        }
    }
}

export default React.memo(LoginPage)
