import { CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import { API } from '../../api/api'
import { UserDetails } from '../../api/response'
import withAppCanvas from '../../components/AppCanvas'
import { MenuItemType } from '../../model/MenuItemType'
import { AppStateContext } from '../../state/appStateContext'
import { changeMenuItem } from '../../state/menuItemActions'
import { showMessage } from '../../state/messageActions'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { setTitle } from '../../state/titleActions'
import { BottomScrollListener } from 'react-bottom-scroll-listener'
import UserTableRow from './UserTableRow'

const strings = {
    column: {
        id: 'Id',
        firstName: 'Imię',
        lastName: 'Nazwisko',
        addingArticles: 'Dodawanie artykułów',
        actions: 'Akcje',
    },
    error: {
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
    },
}

//#region Props & State
interface ComponentProps extends RouteComponentProps {}

interface ComponentState {
    items: UserDetails[]
    loading: boolean
    loadMore: boolean
}

const initialState: ComponentState = {
    items: [],
    loading: false,
    loadMore: true,
}
//#endregion

class UsersPage extends React.Component<ComponentProps, ComponentState> {
    static contextType = AppStateContext
    context!: React.ContextType<typeof AppStateContext>

    constructor(props: ComponentProps) {
        super(props)

        this.state = initialState
    }

    //#region Lifecycle
    public componentDidMount(): void {
        const { state, dispatch } = this.context
        const { menuItem, title } = state

        if (menuItem !== MenuItemType.Users) {
            dispatch(changeMenuItem(MenuItemType.Users))
        }

        if (title !== MenuItemType.Users) {
            dispatch(setTitle(MenuItemType.Users))
        }

        this.load()
    }

    public render(): React.ReactNode {
        const { loading, items } = this.state

        if (loading && items.length === 0) {
            return (
                <Grid
                    container={true}
                    justifyContent="center"
                >
                    <CircularProgress />
                </Grid>
            )
        }

        return (
            <BottomScrollListener
                onBottom={this.onDocumentBottom}
                offset={300}
                debounce={100}
                debounceOptions={{ leading: false, trailing: true }}
                triggerOnNoScroll={false}
            >
                <Paper elevation={2}>
                    <Table size="small">
                        <colgroup>
                            <col width="10%" />
                            <col width="25%" />
                            <col width="25%" />
                            <col width="15%" />
                            <col width="20%" />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell>{strings.column.id}</TableCell>
                                <TableCell>{strings.column.firstName}</TableCell>
                                <TableCell>{strings.column.lastName}</TableCell>
                                <TableCell>{strings.column.addingArticles}</TableCell>
                                <TableCell>{strings.column.actions}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{items.map(this.renderItem)}</TableBody>
                    </Table>
                </Paper>
            </BottomScrollListener>
        )
    }
    //#endregion

    //#region Private
    private async load(): Promise<void> {
        const { items, loadMore, loading } = this.state

        if (loading || !loadMore) {
            return
        }

        this.setState({ loading: true })

        try {
            const response = await API.users.list(20, this.state.items.length)
            const users = items.concat(response)
            this.setState({ loading: false, items: users, loadMore: response.length > 0 })
        } catch (error) {
            this.setState({ loading: false })
        }
    }

    private onDocumentBottom = (): void => {
        this.load()
    }

    private onAllowAddingArticlesChanged = async (item: UserDetails, allowAddingArticles: boolean): Promise<void> => {
        const { dispatch } = this.context

        try {
            dispatch(setInProgress())
            const response = await API.users.changeAllowAddingArticles(item.id, allowAddingArticles)

            const { items } = this.state
            this.setState({
                items: items.map((value: UserDetails): UserDetails => {
                    if (value.id === response.id) {
                        return response
                    }

                    return value
                }),
            })
            dispatch(setSuccess())
        } catch (error) {
            dispatch(setFailure())

            if (error === null) {
                dispatch(showMessage(strings.error.unknown))
            }
        }
    }

    private renderItem = (item: UserDetails): React.ReactNode => {
        return (
            <UserTableRow
                key={item.id}
                item={item}
                onAllowAddingArticlesChanged={this.onAllowAddingArticlesChanged}
            />
        )
    }
    //#endregion
}

export default withAppCanvas(UsersPage)
