import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import HttpsRedirect from './components/HttpsRedirect'
import App from './modules/app/App'

ReactDOM.render(
    <React.StrictMode>
        <HttpsRedirect>
            <App />
        </HttpsRedirect>
    </React.StrictMode>,
    document.getElementById('root')
)

reportWebVitals()
