import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import React from 'react'
import { MenuItemType } from '../model/MenuItemType'

interface OwnProps {
    onChange: (menuItem: MenuItemType, path: string) => void
    isSelected: boolean
    SvgIcon: React.ComponentType<SvgIconProps>
    menuItem: MenuItemType
    path: string
}

type ComponentProps = OwnProps

class DrawerListItem extends React.Component<ComponentProps> {
    shouldComponentUpdate(nextProps: Readonly<ComponentProps>): boolean {
        const { isSelected, menuItem } = this.props

        return isSelected !== nextProps.isSelected || menuItem !== nextProps.menuItem
    }

    public render(): React.ReactNode {
        const { isSelected, menuItem, SvgIcon } = this.props

        return (
            <ListItem
                onClick={this.onListItemClick}
                button={true}
                selected={isSelected}
            >
                <ListItemIcon>
                    <SvgIcon />
                </ListItemIcon>
                <ListItemText primary={menuItem} />
            </ListItem>
        )
    }

    private onListItemClick = (): void => {
        const { onChange, menuItem, path } = this.props

        onChange(menuItem, path)
    }
}

export default DrawerListItem
