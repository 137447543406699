import React from 'react'
import { isLocalhost } from '../utils/isLocalhost'

interface ChildrenProps {
    children: JSX.Element
}

const HttpsRedirect = ({ children }: ChildrenProps): React.ReactElement | null => {
    if (window.location.protocol === 'http:' && !isLocalhost(window.location.hostname)) {
        window.location.protocol = 'https'

        return null
    }

    return children
}

export default React.memo(HttpsRedirect)
